import styled, { css } from 'styled-components';
import { device } from 'theme';
import { MediumTitle, MetaHeading } from '@atb-ventures/personas-components';

export const PeopleOfTomorrowContainer = styled.section`
  padding: 0 40px 30px;
  max-width: 1450px;
  margin: 30px auto;

  @media ${device.medium} {
    padding: 0 30px 30px;
  }
`;

export const TabsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabContentContainer = styled.div``;

export const TabItemContainer = styled.div<{ isSelected: boolean }>`
  display: none;
  ${(props) =>
    props.isSelected &&
    css`
      display: block;
    `}
`;

export const TabTitleContainer = styled.div<{ isSelected: boolean }>`
  ${MetaHeading} {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 12px;
  }

  ${MediumTitle} {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #6d7072;
    cursor: pointer;
    padding-bottom: 16px;
    border-bottom: 1px solid transparent;

    ${(props) =>
    props.isSelected &&
      css`
        color: #000000;
        padding-bottom: 16px;
        border-bottom: 1px solid #0072F0;
      `}
  }
`;

export const ImageContainer = styled.div`
  .isSelected {
    .gatsby-image-wrapper {
      cursor: auto;
      filter: grayscale(0);
    }
  }
  :not(.isSelected) {
    .gatsby-image-wrapper {
      filter: grayscale(100%);
    }
    @media ${device.mediumOnly} {
      :hover {
        .gatsby-image-wrapper {
          cursor: pointer;
          filter: grayscale(100%) sepia(65%) brightness(51%) hue-rotate(180deg)
            saturate(137%) contrast(75%);
        }
        .img-header {
          opacity: 1;
          & span {
            cursor: pointer;
          }
        }
      }
    }
  }
  .img-header {
    color: white;
    font-family: Ubuntu;
    font-size: 35px;
    line-height: 40px;
    width: 0;
    font-weight: 200;
    position: absolute;
    z-index: 1;
    opacity: 0;
  }
  @media ${device.medium} {
    .img-header {
      display: none;
    }
  }
  @media ${device.large} {
    .img-header {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .group-1.img-header {
    top: 50%;
    left: 20%;
  }
  .group-2.img-header {
    top: 23%;
    right: 42%;
  }
  .group-3.img-header {
    top: 90%;
    right: 90%;
  }
`;

export const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  transform: translateY(-50px);

  .group-1 {
    grid-area: 1 / 1 / 2 / 2;
    transform: translateY(80px);
  }
  .group-3 {
    transform: translateY(-60px);
    grid-area: 2 / 2 / 3 / 3;
  }

  @media ${device.small} {
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-column-gap: 0;
    transform: none;
    .group-1,
    .group-3 {
      grid-area: auto;
      transform: none;
    }

    @media ${device.small} {
      grid-template-columns: 1fr 1.5fr 1fr;
      grid-column-gap: 0;
      transform: none;
      overflow-y: scroll;
      .group-1,
      .group-3 {
        grid-area: auto;
        transform: none;
      }
      .group-2 {
        align-self: center;
        margin: 0 -60px;
        justify-self: center;
      }
    }

    @media ${device.xsmall} {
      .group-2 {
        margin: 0 -80px;
      }
    }
  }
`;

export const TabsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  grid-auto-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;

  .tabTitlesContainer {
    display: flex;
    margin-bottom: 20px;
  }

  ${GalleryContainer} {
    grid-area: 1 / 2 / 3 / 3;
  }

  @media ${device.small} {
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;

    ${GalleryContainer} {
      grid-area: 2 / 1 / 3 / 2;
    }

    .tabTitlesContainer {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`;
