import { MediumTitle } from '@atb-ventures/personas-components';
import React, { FC } from 'react';
import { SubpageHeaderContainer, TitleContainer } from './subpage-header.style';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { device } from 'theme';
import Breadcrumbs, { Breadcrumb } from 'components/breadcrumbs';

interface Props {
  title?: string;
  breadcrumbs: Breadcrumb[];
}

const SubpageHeader: FC<Props> = ({ title, breadcrumbs }) => {
  const data = useStaticQuery(graphql`
    query personasSubpageHeaderQuery {
      json: personasJson(id: { eq: "subpage-header" }) {
        image {
          childImageSharp {
            fluid(maxWidth: 515, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        mobileImage {
          childImageSharp {
            fluid(maxWidth: 315, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);

  const sources = [
    data.json.mobileImage.childImageSharp.fluid,
    {
      ...data.json.image.childImageSharp.fluid,
      media: device.mediumOnly,
    },
  ];

  return (
    <SubpageHeaderContainer>
      <TitleContainer>
        {title && <MediumTitle>{title}</MediumTitle>}
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </TitleContainer>
      <Img fluid={sources} />
    </SubpageHeaderContainer>
  );
};

export default SubpageHeader;
