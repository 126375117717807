import styled from 'styled-components';
import { device } from 'theme';

export const LastBreadcrumb = styled.h6`
  display: inline-block;

  @media ${device.small} {
    display: none;
  }
`;

export const BreadcrumbContainer = styled.div<{ isPrevious: boolean }>`
  display: inline-block;

  @media ${device.small} {
    display: ${(props) => (props.isPrevious ? 'flex' : 'none')};
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    img {
      display: ${(props) => (props.isPrevious ? 'inline-block' : 'none')};
      transform: rotateY(180deg);
    }
  }
`;
