import {
  Paragraph,
  MetaHeading,
  MediumTitle,
} from '@atb-ventures/personas-components';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { HeroCallout } from '../hero-callout';
import {
  GalleryContainer,
  ImageContainer,
  PeopleOfTomorrowContainer,
  TabContentContainer,
  TabItemContainer,
  TabsContainer,
  TabsTitleContainer,
  TabTitleContainer,
} from './people-of-tomorrow.style';
import Img from 'gatsby-image';
import { useTabs } from 'utils/useTabs';
import { device } from 'theme';
import WrappedPersonasLink from '../personas-link';

const isBrowser = typeof window !== 'undefined';

interface Images {
  headshot: any;
  mobileHeadshot: any;
  middleHeadshotMobileImage: any;
}

interface Group {
  id: string;
  name: string;
  title: string;
  body: string;
  cta: {
    link: string;
  };
}

interface Tab extends Group {
  sources?: any;
}

export const PeopleOfTomorrow = () => {
  if (!isBrowser) {
    return null;
  }

  const data = useStaticQuery(graphql`
    query personasOverviewQuery {
      json: personasJson(id: { eq: "personas-overview" }) {
        groups {
          id
          name
          title
          body
          cta {
            link
          }
          headshot {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
                aspectRatio
              }
            }
          }
          mobileHeadshot {
            childImageSharp {
              fixed(width: 155, height: 193, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          middleHeadshotMobileImage: mobileHeadshot {
            childImageSharp {
              fixed(width: 231, height: 193, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  if (!data?.json) {
    return null;
  }
  const tabs: Tab[] = data.json.groups.map((group: Group & Images) => ({
    ...group,
    sources: [
      {
        ...(group.id === 'group-2'
          ? group.middleHeadshotMobileImage.childImageSharp.fixed
          : group.mobileHeadshot.childImageSharp.fixed),
        media: device.small,
      },
      {
        ...group.headshot.childImageSharp.fluid,
        media: device.smallOnly,
      },
    ],
  }));

  const { selectedOption, handleTabSelect } = useTabs({
    tabs,
  });

  const toggleSelection = (tabId: string) =>
    handleTabSelect({ target: { value: tabId } } as any);

  return (
    <PeopleOfTomorrowContainer>
      <HeroCallout
        metaHeading="THE PEOPLE"
        title="People of Tomorrow"
        body="The three user groups we have defined are:"
      />
      <TabsContainer>
        <TabsTitleContainer>
          {tabs.map((tab) => (
            <TabTitleContainer
              key={tab.id}
              id={tab.id}
              isSelected={selectedOption === tab.id}
              onClick={() => toggleSelection(tab.id)}
            >
              <MetaHeading>{tab.name}</MetaHeading>
              <MediumTitle>{tab.title}</MediumTitle>
            </TabTitleContainer>
          ))}
        </TabsTitleContainer>
        <TabContentContainer>
          {tabs.map((tab) => (
            <TabItemContainer
              key={tab.id}
              isSelected={selectedOption === tab.id}
            >
              <Paragraph>{tab.body}</Paragraph>
              <WrappedPersonasLink href={tab.cta.link}>
                Explore {tab.title}
              </WrappedPersonasLink>
            </TabItemContainer>
          ))}
        </TabContentContainer>
        <GalleryContainer>
          {tabs.map((tab) => (
            <ImageContainer
              key={tab.id}
              className={`${tab.id} ${
                selectedOption === tab.id ? 'isSelected' : ''
              }`}
              onClick={() => toggleSelection(tab.id)}
            >
              <div className={`${tab.id} img-header`}>
                <span>{tab.title}</span>
              </div>
              <Img fixed={tab.sources} />
              <Img fluid={tab.sources} />
            </ImageContainer>
          ))}
        </GalleryContainer>
      </TabsContainer>
    </PeopleOfTomorrowContainer>
  );
};

export default PeopleOfTomorrow;
