import { Link } from 'gatsby';
import React, { FC } from 'react';
import Caret from '../../assets/images/Caret.svg';
import { BreadcrumbContainer, LastBreadcrumb } from './breadcrumbs.style';

export interface Breadcrumb {
  path: string;
  title: string;
}

interface Props {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumb: FC<Breadcrumb> = ({ path, title }) => <Link to={path}>{title}</Link>;

export const Breadcrumbs: FC<Props> = ({ breadcrumbs }) => {
  return (
    <>
      {breadcrumbs.map((crumb, idx) => {
        const isLast = idx === breadcrumbs.length - 1;
        const isPrevious = idx === breadcrumbs.length - 2;

        if (isLast) {
          return (
            <LastBreadcrumb key={`breadcrumbs-${idx}`}>
              {crumb.title}
            </LastBreadcrumb>
          );
        } else {
          return (
            <BreadcrumbContainer
              key={`breadcrumbs-${idx}`}
              isPrevious={isPrevious}
            >
              <Breadcrumb {...crumb} /> <img src={Caret} />
            </BreadcrumbContainer>
          );
        }
      })}
    </>
  );
};

export default Breadcrumbs;
