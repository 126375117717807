import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledLink = styled(Link)`
  &:hover {
    p {
      color: #000;
    }
  }
`;
