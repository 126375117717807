import { MetaHeading, Paragraph, Title } from '@atb-ventures/personas-components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { device } from 'theme';

interface Props {
  metaHeading?: string;
  title?: string;
  body?: string;
  className?: string;
}

export const ContentContainer = styled.div`
  width: 100%;
  ${Paragraph} {
    margin: 35px 0;

    @media ${device.medium} {
      margin: 16px 0;
    }
  }
  ${MetaHeading} {
    margin: 20px 0;

    @media ${device.medium} {
      margin: 8px 0;
    }
  }
`;

export const HeroCallout: FC<Props> = ({
  metaHeading,
  title,
  body,
  className = '',
}) => (
  <ContentContainer className={className}>
    {metaHeading && <MetaHeading>{metaHeading}</MetaHeading>}
    {title && <Title>{title}</Title>}
    {body && <Paragraph>{body}</Paragraph>}
  </ContentContainer>
);
