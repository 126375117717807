import React, { FC } from 'react';
import { Link as PCLink } from '@atb-ventures/personas-components';
import { StyledLink } from './personas-link.style';

interface Props {
  href: string;
}

export const WrappedPersonasLink: FC<Props> = ({ href, children, ...rest }) => (
  <StyledLink to={href}>
    <PCLink as="p" {...rest}>
      {children}
    </PCLink>
  </StyledLink>
);

export default WrappedPersonasLink;
