import styled from 'styled-components';
import { device } from 'theme';

export const SubpageHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(300px, 530px);
  align-items: center;
  padding: 40px 0;

  @media ${device.medium} {
    grid-template-columns: 1fr 330px;
  }
`;

export const TitleContainer = styled.div`
  padding-left: 40px;

  @media ${device.medium} {
    padding-left: 30px;
  }
`;
